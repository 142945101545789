<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                <span>功能名称：</span>
                            </td>
                            <td>
                                <el-input v-model="searchItem.listName" size="small" placeholder="请输入功能名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                <span>功能编号：</span>
                            </td>
                            <td>
                                <el-input v-model="searchItem.listNum" size="small" placeholder="请输入功能编号"
                                ></el-input>
                            <td style="width: 80px">
                                <span>功能类型：</span>
                            </td>
                            <td>
                                <el-select v-model="searchItem.listType" style="width: 96%" size="small"
                                           placeholder="请选择功能类型">
                                    <el-option
                                            v-for="item in listTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                <span>设备类型：</span>
                            </td>
                            <td>
                                <el-select v-model="searchItem.deviceType" style="width: 96%" size="small"
                                           placeholder="请选择设备类型">
                                    <el-option
                                            v-for="item in deviceTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 250px">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtu">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           v-if="hasAuthority('func_list_add')" @click="addFuncList">
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                            <el-table-column prop="listNum" label="功能编号" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="listName" label="功能名称" width="360" align="center"></el-table-column>
                            <el-table-column prop="listType" label="功能类型" width="180" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.listType == 0">基础功能</span>
                                    <span v-else>选配功能</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="deviceType" label="设备类型" width="180" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.deviceType == 0">塔机</span>
                                    <span v-else-if="scope.row.deviceType == 1">升降机</span>
                                    <span v-else-if="scope.row.deviceType == 10">部件</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="listConfigStr" label="物料配置" align="left"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" width="120" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑"
                                           v-if="hasAuthority('func_list_update')"
                                           @click="editFuncList(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="详情"
                                           @click="showFuncList(scope.row.id)"></i>
                                        <i class="iconfont iconshanchu" title="删除"
                                           v-if="hasAuthority('func_list_delete')"
                                           @click="deleteFuncList(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog">
            <el-dialog
                    :title="title"
                    :visible.sync="dialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-form :model="funcListForm" :rules="rules" ref="funcListForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="功能编号:" prop="listNum">
                                <el-input v-model="funcListForm.listNum"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="功能名称:" prop="listName">
                                <el-input v-model="funcListForm.listName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="功能类型:" prop="listType">
                                <el-select v-model="funcListForm.listType" style="width:100%;" placeholder="请选择功能类型" @change="funTypeBlur">
                                    <el-option
                                            v-for="item in listTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="设备类型:" prop="deviceType">
                                <el-select v-model="funcListForm.deviceType" style="width:100%;" placeholder="请选择设备类型" @change="componentBlur">
                                    <el-option
                                            v-for="item in deviceTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="物料配置:" prop="listConfig">
                        <div class="edit-btn">
                            <table style="width: 50%;">
                                <tr>
                                    <td>
                                        <el-select v-model="searchEditItem.component" style="width: 96%" size="small"
                                                   @change="getMaterialList"
                                                   filterable
                                                   placeholder="请选择部件">
                                            <el-option
                                                    v-for="(item,index) in partList"
                                                    :key="index"
                                                    :label="item.componentName"
                                                    :value-key="index"
                                                    :value="index">
                                            </el-option>
                                            <!--                                            <el-option-->
                                            <!--                                                    v-for="item in partList"-->
                                            <!--                                                    :key="item.component"-->
                                            <!--                                                    :label="item.componentName"-->
                                            <!--                                                    :value="item">-->
                                            <!--                                            </el-option>-->
                                        </el-select>
                                    </td>
                                    <td width="110">
                                        <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                                   @click="addPart">
                                            增加部件
                                        </el-button>
                                    </td>
                                    <td width="110">
                                        <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                                   :disabled="isDisable" @click="addMaterial">
                                            增加其它物料
                                        </el-button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <el-table :data="materialData" max-height="360" style="width: 100%" border
                                  :header-cell-style="{ background: '#EBEEF8',height: '50px',padding: '0', 'text-align':'center' }"
                                  :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column prop="materialCode" label="物料编号" align="center"></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"></el-table-column>
                            <el-table-column prop="model" label="产品型号" align="center"></el-table-column>
                            <el-table-column prop="useNatureName" label="使用性质" align="center"></el-table-column>
                            <el-table-column prop="componentName" label="部件名称" align="center"></el-table-column>
                            <el-table-column prop="count" label="数量" align="center">
                                <template slot-scope="scope">
                                    <el-input type="tel" size="small"
                                              v-model.number="scope.row.count"
                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                              placeholder="请输入数量"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="70px">
                                <template slot-scope="scope">
                                    <i class="iconfont iconshanchu" style="margin-right: 10px" title="删除物料"
                                       @click="deleteMaterial(scope.$index)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('funcListForm')">取 消</el-button>
                    <el-button type="primary" @click="saveFuncList('funcListForm',type)"
                               :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="edit-dialog">
            <el-dialog title="其他物料信息" :visible.sync="dialogTableVisible" center :close-on-click-modal="false">
                <el-table :data="gridData" max-height="360" style="width: 100%" border
                          :header-cell-style="{ background: '#EBEEF8',height: '50px',padding: '0', 'text-align':'center' }"
                          :cell-style="{padding:'0',height: lineHeight}"
                          @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="40" align="center"></el-table-column>
                    <el-table-column prop="materialCode" label="物料编号" align="center"></el-table-column>
                    <el-table-column prop="materialName" label="产品名称" align="center"></el-table-column>
                    <el-table-column prop="model" label="产品型号" align="center"></el-table-column>
                    <el-table-column prop="useNatureName" label="使用性质" align="center"></el-table-column>
                    <el-table-column prop="componentName" label="部件名称" align="center"></el-table-column>
                    <el-table-column prop="count" label="数量" align="center">
                        <template slot-scope="scope">
                            <el-input type="tel" size="small" v-if="!scope.row.checked"
                                      v-model.number="scope.row.count"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      placeholder="请输入数量" disabled></el-input>
                            <el-input type="tel" size="small" v-else v-model.number="scope.row.count"
                                      placeholder="请输入数量"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button type="formal" @click="cancelOther">取 消</el-button>
                    <el-button type="primary" @click="submitOther">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="dialog-info">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <table width="100%">
                    <tr>
                        <td width="80px">
                            功能编号:
                        </td>
                        <td>
                            {{funcListForm.listNum}}
                        </td>
                        <td width="80px">
                            功能名称:
                        </td>
                        <td>
                            {{funcListForm.listName}}
                        </td>
                        <td width="80px">
                            功能类型:
                        </td>
                        <td>{{funcListForm.listType == 0?'基础功能':'选配功能'}}</td>
                        <td width="80px">
                            设备类型:
                        </td>
                        <td>{{funcListForm.deviceType == 0?'塔机':funcListForm.deviceType == 1?'升降机':funcListForm.deviceType == 10?'部件':'未知类型'}}</td>
                    </tr>
                </table>
                <el-table :data="materialData" max-height="360" style="width: 100%" border
                          :header-cell-style="{ background: '#EBEEF8',height: '50px',padding: '0', 'text-align':'center' }"
                          :cell-style="{padding:'0',height: lineHeight}">
                    <el-table-column prop="materialCode" label="物料编号" align="center"></el-table-column>
                    <el-table-column prop="materialName" label="产品名称" align="center"></el-table-column>
                    <el-table-column prop="model" label="产品型号" align="center"></el-table-column>
                    <el-table-column prop="useNatureName" label="使用性质" align="center"></el-table-column>
                    <el-table-column prop="count" label="数量" align="center"></el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [16, 20, 30, 50],
                tableHeight: '',
                isLoad: false,
                searchEditItem: {
                    component: '',
                },
                title: '',
                visible: false,
                isDisable: true,
                infoDialogVisible: false,
                dialogTableVisible: false,
                lineHeight: '',
                dialogVisible: false,
                funcListForm: {
                    listNum: '',
                    listName: '',
                    listType: 0,
                    listConfigStr: '',
                    deviceType: 0,
                    id: '',
                },
                rules: {
                    listNum: [{
                        required: true, message: '请输入功能编号', trigger: 'blur'
                    }],
                    listName: [{
                        required: true, message: '请输入功能名称', trigger: 'blur'
                    }],
                },
                tableData: [],
                gridData: [],
                searchItem: {
                    listNum: '',
                    deviceType: null,
                    listType: null,
                    listName: '',
                    pageIndex: 1,
                    pageSize: 16
                },
                listTypes: [
                    {
                        label: '基础功能',
                        value: 0
                    },
                    {
                        label: '选配功能',
                        value: 1
                    }
                ],
                deviceTypes: [
                    {
                        label: '塔机',
                        value: 0
                    },
                    {
                        label: '升降机',
                        value: 1
                    },
                    {
                        label: '部件',
                        value: 10
                    }
                ],
                multipleSelection: [],
                delList: [],
                totalPage: 0,
                // totalPage:30,
                option: {
                    pageIndex: '',
                    pageSize: 16,
                    currentPage: 2
                },
                pageIndex: 1,
                materialData: [],

                materialList: [],
                partList: [],
                material: {},
                showTitle: '',

            }
        },
        methods: {
            searchBtu() {
                this.pageChange(1)
            },
            reset() {
                this.searchItem = {
                    listNum: '',
                    listType: null,
                    listName: '',
                    pageIndex: 1,
                    pageSize: 16
                }
                this.pageChange(1);
            },
            inputEvent(val) {
                this.visible = true;
            },
            addFuncList() {
                this.title = '新增功能配置'
                this.type = 'save'
                this.isDisable = true;
                this.searchEditItem = {
                    component: '',
                    other: ''
                }
                this.materialData = [];
                this.partList = [];
                this.gridData = [];
                this.material = {};
                this.funcListForm = {
                    id: null,
                    listNum: '',
                    listName: '',
                    deviceType: 0,
                    listType: 0,
                    listConfigStr: ''
                };
                this.$api.getFuncListById({id: null}).then(res => {
                    if (res.code == 200) {
                        this.partList = res.data.allMaterialList;
                        this.dialogVisible = true;
                    }
                })
            },
            editFuncList(id) {
                this.title = '修改功能配置'
                this.type = 'edit'
                this.isDisable = true;
                this.isLoad = false;
                this.materialData = [];
                this.materialList = [];
                this.partList = [];
                this.gridData = [];
                this.searchEditItem = {
                    component: '',
                    other: ''
                }
                this.dialogVisible = true;
                this.$api.getFuncListById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.partList = res.data.allMaterialList;
                        this.dialogVisible = true;
                        this.funcListForm = {
                            id: res.data.id,
                            listNum: res.data.listNum,
                            listName: res.data.listName,
                            deviceType: res.data.deviceType,
                            listType: res.data.listType,
                            listConfigStr: '',
                        };
                        this.materialData = res.data.materialList
                    }
                })
            },
            showFuncList(id) {
                this.$api.getFuncListById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.partList = res.data.allMaterialList;
                        this.infoDialogVisible = true;
                        this.funcListForm = {
                            id: res.data.id,
                            listNum: res.data.listNum,
                            listName: res.data.listName,
                            deviceType: res.data.deviceType,
                            listType: res.data.listType,
                            listConfigStr: '',
                        };
                        this.showTitle = '《' + res.data.listName + '》详细信息'
                        this.materialData = res.data.materialList
                    }
                })
            },
            deleteFuncList(id) {
                this.$confirm('你确定要删除此条功能配置吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteFunList({id}).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            })
                            this.pageChange("refresh");
                        } else {
                            this.$message.error('删除失败，请联系管理员')
                        }
                    })
                }).catch(() => {
                })
            },
            cancelOther() {
                this.dialogTableVisible = false;
            },
            submitOther() {
                for (var item of this.gridData) {
                    if (item.checked) {
                        if (item.count > 0 && item.count != undefined && item.count != null && item.count != '') {
                            this.materialData.push(item)
                        } else {
                            this.$message.error('所选物料的数量不能为空或者为0')
                            return;
                        }
                    }
                }
                this.dialogTableVisible = false;
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
            },
            saveFuncList(formName, type) {
                this.isLoad = true;
                var config = '';
                for (var item of this.materialData) {
                    if (item.count == '' || item.count == 0 || item.count == undefined) {
                        continue;
                    } else {
                        if (config == '') {
                            config = item.id + ',' + item.count;
                        } else {
                            config = config + '|' + item.id + ',' + item.count
                        }
                    }
                }
                this.funcListForm.listConfigStr = config;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (type == 'save') {
                            this.$api.saveFuncList(this.funcListForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.dialogVisible = false;
                                    this.isLoad = false;
                                    this.$message.success(res.message);
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        } else {
                            this.$api.updateFuncList(this.funcListForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.isLoad = false;
                                    this.dialogVisible = false;
                                    this.$message.success(res.message);
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                    }
                });
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageFuncList(this.searchItem).then(
                    res => {
                        if (res.code == 200) {
                            this.tableData = res.data.content;
                            this.totalPage = res.data.totalElements;
                        } else {
                            this.$message.error("连接网络失败")
                        }
                    }
                )
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageFuncList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            getMaterialList(val) {
                this.isDisable = false;
                for (var i = 0; i < this.partList.length; i++) {
                    if (i == val) {
                        this.materialList = this.partList[i].baseList;
                        for (var valElement of this.partList[i].otherList) {
                            valElement.checked = false;
                        }
                        this.gridData = this.partList[i].otherList;
                    }
                }
            },
            addPart() {
                for (var item1 of this.materialList) {
                    this.materialData.push(item1)
                }
                this.materialData = this.uniqueId(this.materialData);
            },
            addMaterial() {
                this.dialogTableVisible = true;
            },
            deleteMaterial(index) {
                this.materialData.splice(index, 1)
            },
            handleSelectionChange(val) {
                var flag = false;
                if (this.multipleSelection.length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.gridData) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.gridData) {
                        item.checked =false;
                    }
                    for (var item of this.gridData) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            uniqueId(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        newArr.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return newArr;
            },
            componentBlur(value){                
                if(value == 10){
                    this.funcListForm.listType = 1;
                }
            },
            funTypeBlur(value){
                if(this.funcListForm.deviceType == 10 && this.funcListForm.listType != 1){
                    this.funcListForm.listType = 1;
                    this.$message.error("部件买卖只能使用选配功能")
                }
            }
        },
        created() {
            this.searchBtu();
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        margin-right: 10px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 5%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
        margin-left: 10px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 260px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .edit-btn {
        text-align: left;
        margin-bottom: 10px;
    }

    .mr10 {
        margin-right: 10px;
    }
</style>
